import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['overwrite', 'trigger'];

  connect() {
    this.toggleOverwrite();
  }

  toggleOverwrite() {
    let taskDetails = this.overwriteTarget.querySelector("#overwrite_task_details");
    let taskAssignments = this.overwriteTarget.querySelector("#overwrite_task_assignments");
    let taskAutomations = this.overwriteTarget.querySelector("#overwrite_task_automations");
    let overwriteBtn = this.overwriteTarget.querySelector("#overwrite-btn");

    if(this.isCheckBoxChecked(taskDetails, taskAssignments, taskAutomations)){
      overwriteBtn.classList.remove("button-disabled");
    } else {
      overwriteBtn.classList.add("button-disabled");
    }
  }

  isCheckBoxChecked(taskDetails, taskAssignments, taskAutomations) {
    return (taskDetails.checked === true) || (taskAssignments.checked === true) || (taskAutomations.checked === true)
  }

  callPropagation(event) {
    event.preventDefault();
    let targetParent = event.target.parentNode;
    let processTemplate = event.target.getAttribute("data-process-template");
    let stepTemplate = event.target.getAttribute("data-step-template");

    let taskDetails = targetParent.querySelector("#overwrite_task_details");
    let taskAssignments = targetParent.querySelector("#overwrite_task_assignments");
    let taskAutomations = targetParent.querySelector("#overwrite_task_automations");

    let data = {
      task_details: taskDetails.checked,
      task_assignments: taskAssignments.checked,
      task_automations: taskAutomations.checked
    };

    if(this.isCheckBoxChecked(taskDetails, taskAssignments, taskAutomations)) {
      fetch(`/${accountId}/process_templates/${processTemplate}/step_templates/${stepTemplate}/propagate_step`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        let targetParentForm = targetParent;
        while(!targetParentForm.classList.contains("dropdown-content")) {
          targetParentForm = targetParentForm.parentElement;
        }

        if(json.dependent_task_list_id) {
          var taskPropagationSetting = targetParentForm.querySelector(`#propagation-step-settings-${json.id}`)
          var taskPropagationMessage = targetParentForm.querySelector(`#propagation-in-progress-message-${json.id}`)

          taskPropagationSetting.classList.add("hidden")
          taskPropagationMessage.classList.remove("hidden")
        }

        let body = document.querySelector("body");
        let flash_message = document.querySelector(".flash-message");
        targetParentForm.classList.add("hidden");

        if(flash_message) {
          flash_message.innerHTML = "Propagation of this step has begun. This might take a few minutes to complete...";
        } else {
          body.insertAdjacentHTML("afterbegin", "<div class=\"flash-message notice\">Propagation of this step has begun. This might take a few minutes to complete...</div>");
          // Reassign to the newly created flash message
          flash_message = document.querySelector(".flash-message");
        }

        // Remove the flash message after 4 seconds
        setTimeout(() => {
          if (flash_message) {
            flash_message.remove();
          }
        }, 4000);
      })
    }
  }
}
