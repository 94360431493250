import consumer from "./consumer";

document.addEventListener('turbolinks:load', () => {
  const globalStatusDiv = document.getElementById('global-task-generation-status');

  const updateGlobalStatus = () => {
    if (globalStatusDiv) {
      const status = localStorage.getItem('taskGenerationStatus');

      if (status === 'generating') {
        globalStatusDiv.textContent = "AI is generating, please wait...";
        globalStatusDiv.classList.remove('failed');
        globalStatusDiv.classList.add('block');

        setTimeout(() => {
          if (localStorage.getItem('taskGenerationStatus') === 'generating') {
            globalStatusDiv.classList.remove('block');
          }
        }, 3000);
      } else if (status === 'failed') {
        globalStatusDiv.textContent = "AI task generation failed, Please try again.";
        globalStatusDiv.classList.add('block', 'failed');

        setTimeout(() => {
          globalStatusDiv.classList.remove('block');
          localStorage.removeItem('taskGenerationStatus');
        }, 3000);
      } else if (status === 'completed') {
        globalStatusDiv.textContent = "AI task generation completed successfully!";
        globalStatusDiv.classList.remove('failed');
        globalStatusDiv.classList.add('block');

        setTimeout(() => {
          globalStatusDiv.classList.remove('block');
          localStorage.removeItem('taskGenerationStatus');
        }, 3000);
      } else {
        globalStatusDiv.classList.remove('block');
      }
    }
  };

  updateGlobalStatus();

  consumer.subscriptions.create({ channel: "TaskGenerationChannel" }, {
    connected() {
      console.log("Connected to TaskGenerationChannel.");
    },

    disconnected() {
      console.log("Disconnected from TaskGenerationChannel.");
    },

    received(data) {
      console.log("Broadcast received:", data);

      if (data.status === 'completed') {
        localStorage.setItem('taskGenerationStatus', 'completed');

        // Store the generated text in localStorage
        if (data.generated_text && data.task_id && data.task_block_id) {
          localStorage.setItem(`trix-content-${data.task_id}-rich_text_input-${data.task_block_id}`, data.generated_text);
        }

        if (data.ai_process_generator) {
          window.location.reload();
        }

        const projectElement = document.querySelector('[data-project-id]');
        if (projectElement) {
          const submitButton = document.getElementById('generate-tasks-form')?.querySelector('input[type="submit"]');
          if (submitButton) {
            submitButton.disabled = false;
          }
          refreshTaskList();
        }
      } else if (data.status === 'failed') {
        localStorage.setItem('taskGenerationStatus', 'failed');
      } else if (data.status === 'generating') {
        localStorage.setItem('taskGenerationStatus', 'generating');
      }

      updateGlobalStatus();
    }
  });
});

function refreshTaskList() {
  const taskListContainer = document.querySelector('.task-list-content');
  if (taskListContainer) {
    fetch(window.location.href, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.text())
    .then(html => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const newTaskList = doc.querySelector('.task-list-content').innerHTML;
      taskListContainer.innerHTML = newTaskList;
      console.log("Task list refreshed");
    })
    .catch(error => {
      console.error('Error refreshing task list:', error);
      alert('Failed to refresh task list.');
    });
  }
}
