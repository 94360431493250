import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "blockEditor", "preFillEditor",
    "blockText", "preFillText",
    "blockDate", "preFillDate",
    "aiPromptInput", "generateButton", "richTextPdfInput"
  ];

  connect() {
    if(this.hasBlockEditorTarget && this.hasPreFillEditorTarget) 
    {
      this.blockEditorTarget.addEventListener("trix-change", () => {
        this.preFillEditorTarget.inputElement.value = this.blockEditorTarget.inputElement.value;
      })
    }
    else if(this.hasBlockTextTarget && this.hasPreFillTextTarget) 
    {
      this.blockTextTarget.addEventListener("keyup", () => {
        this.preFillTextTarget.value = this.blockTextTarget.value;
      })
    }
    else if(this.hasBlockDateTarget && this.hasPreFillDateTarget) 
    {
      this.blockDateTarget.addEventListener("change", () => {
        this.preFillDateTarget.value = this.blockDateTarget.value;
      });
    }
  }

  generateAIText(event) {
    event.preventDefault();

    let aiPromptValue;

    if (this.hasAiPromptInputTarget) {
      aiPromptValue = this.aiPromptInputTarget.value;
    } else {
      const blockId = this.element.dataset.blockId;
      const aiPromptElement = document.getElementById(`ai-prompt-${blockId}`);

      if (aiPromptElement) {
        aiPromptValue = aiPromptElement.textContent.trim();
      } else {
        console.error(`AI Prompt element with ID ai-prompt-${blockId} not found.`);
        alert("AI Prompt element not found. Please check the block ID.");
        return;
      }
    }

    if (!aiPromptValue) {
      alert("AI Prompt value is empty. Please provide a valid prompt.");
      return;
    }

    // Get the URL from the button's data attribute
    const url = this.generateButtonTarget.dataset.url;

    // Create a FormData object and append the prompt value
    const formData = new FormData();
    formData.append('ai_prompt', aiPromptValue);

    // Send the PUT request using fetch
    fetch(url, {
      method: "PUT", // Use PUT method
      headers: {
        "X-Requested-With": "XMLHttpRequest", // Rails expects this header for AJAX requests
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content // CSRF token
      },
      body: formData
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Expecting text or HTML
      })
      .then((data) => {
        console.log("AI Text generated successfully:", data);
        window.location.reload();
        // Optionally, update the UI with the generated text
      })
      .catch((error) => {
        console.error("Error generating AI Text:", error);
      });
  }

  generatePDF(event) {
    event.preventDefault();

    let richTextPdfInputValue;
    richTextPdfInputValue = this.richTextPdfInputTarget.value;

    const url = this.generateButtonTarget.dataset.url;

    const formData = new FormData();
    formData.append('rich_text_value', richTextPdfInputValue);

    // Send the PUT request using fetch
    fetch(url, {
      method: "PUT", // Use PUT method
      headers: {
        "X-Requested-With": "XMLHttpRequest", // Rails expects this header for AJAX requests
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content // CSRF token
      },
      body: formData
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Expecting text or HTML
      })
      .then((data) => {
        console.log("PDF generated successfully:", data);
        window.location.reload();
        // Optionally, update the UI with the generated text
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }
}
