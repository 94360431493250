import consumer from "./consumer";

document.addEventListener('turbolinks:load', () => {
  consumer.subscriptions.create(
    { channel: "TaskStatusChannel"},
    {
      connected() {
        console.log("Connected to TaskStatusChannel.");
      },

      received(data) {
        if (data.status === "completed") {
          Turbolinks.visit(window.location.href);

          const globalStatusDiv = document.getElementById('global-task-generation-status');

          globalStatusDiv.textContent = "PDF generated successfully!";
          globalStatusDiv.classList.add('block');

          setTimeout(() => {
            globalStatusDiv.classList.remove('block');
            localStorage.removeItem('taskGenerationStatus');
          }, 3000);
        }
      }
    }
  );
});
