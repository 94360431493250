import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const userTimeZone = this.element.dataset.flatpickrUserTimezone; // Fetch the user's timezone from the data attribute.
    const defaultCurrentTime = this.element.dataset.defaultCurrentTime === 'true'; // Check if default_current_time is true.
    const calendarColor = this.element.dataset.calendarColor;  // Fetch the dynamic calendar color

    // Function to calculate the user's local time using the time zone offset
    const getUserTimeZoneTime = () => {
      const currentDate = new Date();
      const localOffset = currentDate.getTimezoneOffset(); // Get the local timezone offset in minutes
      const userOffset = new Date().getTimezoneOffset(); // Get the user's time zone offset in minutes

      // Convert time to the user's time zone
      const userTime = new Date(currentDate.getTime() - (localOffset - userOffset) * 60000);

      return { hour: userTime.getHours(), minute: userTime.getMinutes() };
    };

    const userTime = getUserTimeZoneTime();

    flatpickr(this.element, {
      enableTime: true,
      animate: false,
      wrap: true,
      disableMobile: "true",
      defaultHour: defaultCurrentTime ? userTime.hour : 9,
      defaultMinute: defaultCurrentTime ? userTime.minute : 0,

      onOpen: function (dateStr, selectedDates, instance) {
        if (instance.element.classList.contains("has-value")) {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");
        } else {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");
        }

        if (calendarColor) {
          applySelectedDateStyles(instance, calendarColor);
        }
      },

      onChange: (dateStr, selectedDates, instance) => {
        if (instance.selectedDates.length === 0) {
          this.element.querySelector(".display-datetime .datetime .date").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");

          const dateDifferenceElement = this.element.querySelector(".date-difference-value");
          if (dateDifferenceElement) {
            dateDifferenceElement.innerHTML = "Just now";
          }
        } else {
          const month = instance.monthElements[0].selectedOptions[0].text.substring(0, 3); // Get month abbreviation
          const day = instance.selectedDateElem.innerText;
          const year = instance.yearElements[0].value;
          const hour = instance.hourElement.valueAsNumber;
          const minutes = instance.minuteElement.value;
          const ampm = instance.amPM.innerText.toLowerCase();

          this.element.querySelector(".display-datetime .datetime .date").innerHTML = `${month} ${day}, ${year}`;
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = `${hour}:${minutes}${ampm}`;
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");

          const dateDifferenceElement = this.element.querySelector(".date-difference-value");
          if (dateDifferenceElement) {
            const niceDateDifference = this.calculateNiceDateDifference(instance.selectedDates[0]);
            dateDifferenceElement.innerHTML = niceDateDifference;
          }
        }

        if (calendarColor) {
          applySelectedDateStyles(instance, calendarColor);
        }
      },

      onReady: function (selectedDates, dateStr, instance) {
        const footer = document.createElement('div');
        footer.style.cssText = `
          text-align: center;
          margin-bottom: 10px;
        `;

        footer.innerHTML = `
          <button type="button" style="
            border-radius: 2px;
            font-size: 14px;
          ">
            Update
          </button>
        `;

        instance.calendarContainer.appendChild(footer);

        footer.querySelector('button').addEventListener('click', () => {
          instance.close();
        });

        // Prevent popup reopening when the clear icon is clicked
        const clearButton = this.element.querySelector(".datetime-actions .icon.clear");
        if (clearButton) {
          clearButton.addEventListener('click', (e) => {
            e.stopPropagation();
          });
        }

        if (calendarColor) {
          applySelectedDateStyles(instance, calendarColor);
        }
      }
    });
  }

  // Method to calculate nice date difference
  calculateNiceDateDifference(selectedDate) {
    const currentDate = new Date();
    const timeDifference = currentDate - selectedDate;
    let result = '';

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;

    if (timeDifference < secondsInMinute * 1000) {
      result = "Just now";
    } else if (timeDifference < secondsInHour * 1000) {
      result = `${Math.floor(timeDifference / (secondsInMinute * 1000))} minutes ago`;
    } else if (timeDifference < secondsInDay * 1000) {
      result = `${Math.floor(timeDifference / (secondsInHour * 1000))} hours ago`;
    } else if (timeDifference < secondsInWeek * 1000) {
      result = `${Math.floor(timeDifference / (secondsInDay * 1000))} days ago`;
    } else if (timeDifference < secondsInMonth * 1000) {
      result = `${Math.floor(timeDifference / (secondsInWeek * 1000))} weeks ago`;
    } else if (timeDifference < secondsInYear * 1000) {
      result = `${Math.floor(timeDifference / (secondsInMonth * 1000))} months ago`;
    } else {
      result = `${Math.floor(timeDifference / (secondsInYear * 1000))} years ago`;
    }

    if (timeDifference < 0) {
      if (Math.abs(timeDifference) < secondsInMinute * 1000) {
        result = "Just now";
      } else if (Math.abs(timeDifference) < secondsInHour * 1000) {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInMinute * 1000))} minutes left`;
      } else if (Math.abs(timeDifference) < secondsInDay * 1000) {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInHour * 1000))} hours left`;
      } else if (Math.abs(timeDifference) < secondsInWeek * 1000) {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInDay * 1000))} days left`;
      } else if (Math.abs(timeDifference) < secondsInMonth * 1000) {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInWeek * 1000))} weeks left`;
      } else if (Math.abs(timeDifference) < secondsInYear * 1000) {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInMonth * 1000))} months left`;
      } else {
        result = `${Math.floor(Math.abs(timeDifference) / (secondsInYear * 1000))} years left`;
      }
    }

    if (Math.abs(timeDifference) < secondsInDay * 1000) {
      const tomorrow = new Date(currentDate.getTime() + secondsInDay * 1000);
      if (selectedDate.getTime() === tomorrow.getTime()) {
        result = "tomorrow";
      }
    }

    if (Math.abs(timeDifference) < secondsInDay * 1000 * 2) {
      const dayAfterTomorrow = new Date(currentDate.getTime() + secondsInDay * 1000 * 2);
      if (selectedDate.getTime() === dayAfterTomorrow.getTime()) {
        result = "day after tomorrow";
      }
    }

    return result;
  }
}

function applySelectedDateStyles(instance, calendarColor) {
  const selectedDate = instance.calendarContainer.querySelector('.flatpickr-day.selected');
  if (selectedDate) {
    selectedDate.style.backgroundColor = calendarColor;
    selectedDate.style.color = '#fff';
  }
}
