// This controller takes care of showing/hiding project schedule options.

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['recurProject', 'recurProjectBlock', 'repeatEvery', 'day', 'checkboxDay', 'dayOfWeek', 'dayOfMonth', 'month', 
                      'appendToTitle', 'appendDateFormat', 'time', 'recureProjectBlock', 'dayOption', 'numberOfWeeks', 'appendCustomFields', 
                      'customFieldOption']

  connect() {
    if(!this.hasRecurProjectTarget) {
      this.showRecurringOptions()
    }
  }

  restrictCheckboxSelection(event) {
    // Check if the clicked checkbox is selected
    if (event.target.checked) {
      // Uncheck all other checkboxes
      this.customFieldOptionTargets.forEach((checkbox) => {
        if (checkbox !== event.target) {
          checkbox.checked = false;
        }
      });
    } else {
      // Prevent unchecking the checkbox if it's the last one selected
      const selectedCheckboxes = this.customFieldOptionTargets.filter(cb => cb.checked);
      if (selectedCheckboxes.length === 0) {
        event.target.checked = true;
      }
    }
  }

  toggleRecurProject() {
    this.element.classList.add("tall-modal");
    if(this.hasRecurProjectTarget && this.recurProjectTarget.checked) {
      this.recurProjectBlockTarget.classList.remove("hidden")
      this.showRecurringOptions()
    } else {
      this.recurProjectBlockTarget.classList.add("hidden")
    }
  }

  ShowHideDay() {
    this.dayOfWeekTarget.parentNode.classList.add("hidden")
    this.dayOfMonthTarget.parentNode.classList.add("hidden")
    this.monthTarget.parentNode.classList.add("hidden")
    this.numberOfWeeksTarget.parentNode.classList.add("hidden")
    this.dayTarget.classList.remove("hidden")

    if(this.repeatEveryTarget.value === "week") {
      this.dayTarget.classList.add("hidden")
      this.dayOfWeekTarget.parentNode.classList.remove("hidden")
      this.numberOfWeeksTarget.parentNode.classList.remove("hidden")
    } else if(this.repeatEveryTarget.value !== "days"){
      this.dayOfMonthTarget.parentNode.classList.remove("hidden")
      if(this.repeatEveryTarget.value === "year")
        this.monthTarget.parentNode.classList.remove("hidden")
    }
  }

  showHideDateFormat() {
    if(this.appendToTitleTarget.value === "2") {
      this.appendDateFormatTarget.classList.remove("hidden")
    } else {
      this.appendDateFormatTarget.classList.add("hidden")
    }

    if (this.appendToTitleTarget.value === "3") {
      this.appendCustomFieldsTarget.classList.remove("hidden")
    } else {
      this.appendCustomFieldsTarget.classList.add("hidden")
    }
  }

  showRecurringOptions() {
    this.recurProjectBlockTarget.classList.remove("hidden")
    this.repeatEveryTarget.parentNode.classList.remove("hidden")
    this.ShowHideDay()
    this.timeTarget.classList.remove("hidden")
    this.appendToTitleTarget.parentNode.classList.remove("hidden")
    this.showHideDateFormat()
  }

  validateBeforeSubmit() {
    var checkedDays = [];
    var perfomValidation = true;
    if(this.hasRecurProjectTarget && !this.recurProjectTarget.checked) {
      perfomValidation = false
    }
    if (this.repeatEveryTarget.value !== "week") {
      this.dayOptionTargets.forEach(day => {
        checkedDays.push(day.checked)
      });

      if(!checkedDays.includes(true) && perfomValidation) {
        event.preventDefault()
        this.dayTarget.insertAdjacentHTML("beforeend",
            "<div class=\"error-message\">At least one day must be selected</div>")
        if(!this.hasRecurProjectTarget) {
          document.querySelector("#project-settings .dropdown-toggle").click()
          document.querySelector("#project-settings #project-recurrence").click()
        }
      }
    }
  }
}
