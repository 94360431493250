import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "dropdown"];

  storeInput(event) {
    this.currentInput = event.target;
  }

  insertTag(event) {
    const selectedTag = event.target.value; 
    if (!this.currentInput || !selectedTag) return;

    const cursorPosition = this.currentInput.selectionStart || 0;
    const inputValue = this.currentInput.value;

    this.currentInput.value = inputValue.slice(0, cursorPosition) + selectedTag + inputValue.slice(cursorPosition);

    event.target.value = "";

    this.currentInput.dispatchEvent(new Event("input", { bubbles: true }));
  }
}
