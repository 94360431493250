document.addEventListener("turbolinks:load", function() {

  const planButtons = document.querySelectorAll(".update-plan-btn");
  const planIdField = document.getElementById("selected-plan-id");

  planButtons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const planId = event.target.dataset.planId;
      planIdField.value = planId;

      document.querySelectorAll(".plan").forEach((plan) => {
        plan.classList.remove("selected-plan");
      });
      event.target.closest(".plan").classList.add("selected-plan");
    });
  });

})